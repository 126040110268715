import { Button, ButtonProps } from "@chakra-ui/react"
import React, { forwardRef } from "react"

import { ThemedButton } from "Shared/components/ThemedButton"
import { useTestBranding } from "UsabilityHub/components/UsabilityTest/context/testBranding"
import { getLumaFromHex } from "Utilities/color"

/**
 * @deprecated Use {@link ThemedButton} instead.
 */
export const LegacyThemedButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (buttonProps, ref) => {
    const branding = useTestBranding()
    const backgroundColor = branding.button_color
    const foregroundColor =
      backgroundColor && getLumaFromHex(backgroundColor) > 160 ? "#000" : "#fff"

    return (
      <Button
        ref={ref}
        colorScheme={backgroundColor === null ? "brand.primary" : ""}
        bg={backgroundColor}
        color={foregroundColor}
        borderColor={backgroundColor}
        _hover={{
          filter: "brightness(95%)",
        }}
        _active={{
          filter: "brightness(90%)",
        }}
        {...buttonProps}
      />
    )
  }
)

LegacyThemedButton.displayName = "LegacyThemedButton"

import { Box, Checkbox, Flex, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import * as Yup from "yup"

import { LegacyThemedButton } from "Components/button/legacy-themed-button"

import { useScreenerQuestionAnswersContext } from "./AnsweredQuestionsContext"
import { QuestionText } from "./QuestionText"
import { ParticipantScreenerQuestion, ScreenerQuestionAnswer } from "./types"

const MultiSelectScreenerQuestionAnswer = Yup.object().shape({
  selected_screener_question_options: Yup.array()
    .of(
      Yup.object().shape({
        question_option_id: Yup.string().required(),
        value: Yup.string().required(),
        isSelected: Yup.boolean(),
      })
    )
    .test(
      "at least one option must be selected",
      "Select at least one option",
      (values) => {
        return values?.some((value) => value.isSelected === true)
      }
    )
    .required(),
})

type MultiSelectScreenerQuestionAnswerValues = Yup.InferType<
  typeof MultiSelectScreenerQuestionAnswer
>

interface MultiSelectScreenerQuestionsAnswerFormProps {
  question: ParticipantScreenerQuestion
  defaultValue: {
    question_option_id: string
    value: string
    isSelected: boolean
  }[]
  onSubmit: (values: ScreenerQuestionAnswer) => void
}

export function MultiSelectScreenerQuestionsAnswerForm({
  question,
  defaultValue,
  onSubmit: onExternalSubmit,
}: MultiSelectScreenerQuestionsAnswerFormProps) {
  const { goToNextQuestion, setIsInvalidAnswer } =
    useScreenerQuestionAnswersContext()

  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { isValid },
  } = useForm<MultiSelectScreenerQuestionAnswerValues>({
    defaultValues: {
      selected_screener_question_options: defaultValue,
    },
    resolver: yupResolver(MultiSelectScreenerQuestionAnswer),
  })

  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "selected_screener_question_options", // unique name for your Field Array
  })

  const onSubmit: SubmitHandler<MultiSelectScreenerQuestionAnswerValues> = (
    values
  ) => {
    onExternalSubmit({
      screener_question_id: question.id,
      type: "multi_select",
      screener_question_options: values.selected_screener_question_options
        .filter((option) => option.isSelected)
        .map((option) => option.question_option_id),
    })
  }

  useEffect(() => {
    const subscription = watch(() => {
      void handleSubmit(onSubmit)()
    })

    return () => subscription.unsubscribe()
  })

  useEffect(() => {
    setIsInvalidAnswer(!isValid)
  }, [isValid])

  return (
    <div>
      <QuestionText>{question.text}</QuestionText>
      <Box mt={5}>
        <form
          onSubmit={handleSubmit((values) => {
            onSubmit(values)
            goToNextQuestion()
          })}
        >
          <Text color="text.secondary" fontSize="md">
            Select as many as apply
          </Text>
          <Flex mt={4} flexDirection="column" gap={2}>
            {fields.map((field, index) => (
              <Checkbox
                key={field.id}
                {...register(
                  `selected_screener_question_options.${index}.isSelected` as const
                )}
              >
                {field.value}
              </Checkbox>
            ))}
          </Flex>
          <LegacyThemedButton mt={8} type="submit" isDisabled={!isValid}>
            Continue
          </LegacyThemedButton>
        </form>
      </Box>
    </div>
  )
}

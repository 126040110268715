import {
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  useMergeRefs,
} from "@chakra-ui/react"
import { tokens } from "DesignSystem/tokens"
import { MutuallyExclusive } from "Shared/types/utility-types/mutuallyExclusive"
import React, { forwardRef } from "react"

/**
 * Note: The Chakra v3 Textarea offers native auto-resize functionality, so once we
 * upgrade to v3 we can simplify this component.
 */

type WhitelistedChakraProps = Pick<
  ChakraTextareaProps,
  | "value"
  | "onChange"
  | "minHeight"
  | "placeholder"
  | "isInvalid"
  | "isDisabled"
  | "isRequired"
  | "isReadOnly"
  | "autoFocus"
  | "onKeyDown"
>

/**
 * - `rows` and `height` are mutually exclusive.
 * - `autoResize` can't be used at the same time as `rows` or `height`.
 */
type HeightManagementProps =
  | (MutuallyExclusive<{
      rows: ChakraTextareaProps["rows"]
      height: ChakraTextareaProps["height"]
    }> & {
      autoResize?: never
    })
  | { rows?: never; height?: never; autoResize?: boolean }

type Props = WhitelistedChakraProps & HeightManagementProps

const resizable = (el: HTMLTextAreaElement | null) => {
  if (!el) return

  let width = el.offsetWidth
  let requestResize: ReturnType<typeof requestAnimationFrame>

  const updateHeight = () => {
    el.style.height = "0px"
    const styles = window.getComputedStyle(el)
    const { borderTopWidth, borderBottomWidth } = styles
    const desiredHeight =
      el.scrollHeight + parseInt(borderTopWidth) + parseInt(borderBottomWidth)
    el.style.height = `${desiredHeight}px`
  }

  const resizeObserver = new ResizeObserver(() => {
    if (el.offsetWidth !== width) {
      width = el.offsetWidth
      if (requestResize) cancelAnimationFrame(requestResize)
      requestResize = requestAnimationFrame(updateHeight)
    }
  })

  resizeObserver.observe(el)
  requestResize = requestAnimationFrame(updateHeight)

  el.addEventListener("input", updateHeight)
  el.addEventListener("change", updateHeight)

  return () => {
    el.removeEventListener("input", updateHeight)
    el.removeEventListener("change", updateHeight)
    resizeObserver.disconnect()
  }
}

/**
 * - For state management, use `value` and `onChange`
 *   - Alternatively you can spread `{...register("fieldName")}` from `react-hook-form`
 * - `placeholder` text is optional
 * - A number of props match those in the Chakra v2 `Textarea`:
 *   - `isInvalid`, `isDisabled`, `isRequired`, `isReadOnly`, `autoFocus`, `onKeyDown`
 * - Use `autoResize` to make the textarea grow to fit its content
 * - Use `minHeight` to control the height on dynamic-height `autoResize` usages
 * - Use `rows` or `height` to control the height on fixed-height usages (can't be used with `autoResize`)
 * - Height should be no less than 3 rows of text by design
 * - Full width by default; the intention is to control width via layout rather than setting it on the component
 */
export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ autoResize, ...props }, ref) => {
    const resizableFn = React.useCallback(resizable, [])
    const mergedRef = autoResize ? useMergeRefs(ref, resizableFn) : ref

    return (
      <ChakraTextarea
        backgroundColor="ds.background.input.resting"
        borderColor="ds.border.input"
        rounded="8px"
        py={2}
        px={3}
        maxW="none"
        resize={autoResize || props.isDisabled ? "none" : "vertical"}
        // The textStyle prop gets overridden by ChakraTextarea
        // so we have to spread the token with sx instead
        sx={{ ...tokens.textStyles.ds.paragraph.primary }}
        transition="none"
        _placeholder={{
          color: "ds.text.subtlest",
        }}
        _hover={{
          backgroundColor: "ds.background.input.hovered",
        }}
        _focusVisible={{
          boxShadow: "none",
          borderColor: "ds.border.focused",
          backgroundColor: "ds.background.input.resting",
        }}
        _invalid={{
          boxShadow: "none",
          borderColor: "ds.border.danger",
        }}
        _disabled={{
          backgroundColor: "ds.background.disabled",
          borderColor: "ds.border.disabled",
          cursor: "not-allowed",
        }}
        {...props}
        ref={mergedRef}
      />
    )
  }
)

TextArea.displayName = "TextArea"

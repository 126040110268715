import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { useNavigate } from "react-router"

import { LegacyThemedButton } from "Components/button/legacy-themed-button"
import { useCreateModeratedStudyBooking } from "~/api/generated/usabilityhub-components"
import { ModeratedStudyApplicationFlow } from "~/api/generated/usabilityhubResponses"

import { TimezoneChanger } from "Shared/components/TimezoneChanger/TimezoneChanger"

import { interviewBookingReceivedGoogle } from "JavaScripts/analytics/google"
import { useModeratedStudyPreviewData } from "UsabilityHub/hooks/useModeratedStudyPreviewData"
import { BookingCalendar, calendarHeight } from "./BookingCalendar"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"
import { RescheduleBookingTimes } from "./RescheduleBookingTimes"

const MotionBox = motion(Box)
export function ModeratedStudyBookingPage() {
  const navigate = useNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()

  // We need to show a loading state for the whole page during the first time retrieving the
  // calendar data. If there is no available slots for the study, the page should be redirected
  // to the thank you page.
  //
  // If no available slots are found when users are browsing the page or when users are invited,
  // the page will show appropriate messages and a "Continue" button.
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [hasAvailableSlots, setHasAvailableSlots] = useState(true)

  const [selectedSlot, setSelectedSlot] = useState<string | null>(null)
  const [selectedTimezone, setSelectedTimezone] = useState(() => {
    // Try to guess default timezone based on the browser
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const {
    application: moderatedStudyApplication,
    nextPageLink,
    invalidateApplicationQuery,
    navigateToThankYouPage,
  } = useModeratedStudyApplicationContext()
  const updatePreviewData = useModeratedStudyPreviewData()

  const { mutateAsync: createBooking, isLoading: isBooking } =
    useCreateModeratedStudyBooking({
      onSuccess: () => {
        interviewBookingReceivedGoogle()

        invalidateApplicationQuery()
      },
      onError: (error) => {
        toast({
          title:
            error.payload.message ?? "There was a problem creating the booking",
          status: "error",
        })

        // Invalidate _all_ calendar data to remove any slots that are no longer available
        return queryClient.invalidateQueries([
          "api",
          "moderated_study_applications",
          moderatedStudyApplication.moderated_study_application_id,
          "booking_slots",
        ])
      },
    })

  if (moderatedStudyApplication.booking?.state === "booked") return null

  const existingBooking = moderatedStudyApplication.booking

  // If we're in the researcher reschedule flow we don't want to allow booking
  // the same date
  const previousSlotSelected =
    existingBooking?.state === "rescheduled_by_researcher" &&
    existingBooking?.starts_at === selectedSlot

  const handleBooking = async (
    startTime: string,
    participantTimezone: string
  ) => {
    const startTimeAsDate = new Date(Date.parse(startTime))

    if (
      moderatedStudyApplication.moderated_study_application_id === "preview"
    ) {
      updatePreviewData({
        booking: {
          id: "preview",
          state: "booked",
          starts_at: startTimeAsDate.toISOString(),
          ends_at: new Date(
            startTimeAsDate.getTime() +
              moderatedStudyApplication.moderated_study.event_duration_mins *
                60 *
                1000
          ).toISOString(),
          hosts: [{ name: "Host name", email: "host@example.com" }],
          participant_timezone: participantTimezone,
          declined_reschedule_request: false,
          incentive_text:
            moderatedStudyApplication.recruitment_link?.incentive_text ?? "",
          incentive_type:
            moderatedStudyApplication.recruitment_link?.incentive_type ??
            "none",
        },
      })
    } else {
      await createBooking({
        pathParams: {
          moderatedStudyApplicationId:
            moderatedStudyApplication.moderated_study_application_id,
        },
        body: {
          starts_at: startTimeAsDate.toISOString(),
          participant_timezone: participantTimezone,
        },
      })
    }

    if (nextPageLink) navigate(nextPageLink)
  }

  // We don't want to treat the canceled states as an existing booking
  const existingBookingId =
    existingBooking?.state === "rescheduled_by_researcher"
      ? existingBooking?.id
      : undefined

  return (
    <AnimatePresence>
      <MotionBox
        width="min-content"
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -200, opacity: 0 }}
        transition={{
          y: {
            type: "spring",
            stiffness: 600,
            damping: 32,
          },
        }}
      >
        {/* This is the min width of the BookingCalendar, and is to keep
        the headings from being squashed by the min-content rule above before
        the calendar has loaded */}
        {!isFirstLoading && (
          <Box minW={["342px", null, "610px"]}>
            <Heading fontSize="2xl" fontWeight="normal">
              Pick a date and time
            </Heading>

            <Heading fontSize="md" fontWeight="normal">
              Let us know when you are available
            </Heading>
          </Box>
        )}
        <VStack mt={10} alignItems="stretch" gap={4}>
          <Grid
            templateColumns={["1fr", null, null, "1fr 236px"]}
            templateRows={["auto", null, null, `${calendarHeight}px auto`]}
            columnGap="30px"
            rowGap={10}
          >
            <BookingCalendar
              moderatedStudyApplicationId={
                moderatedStudyApplication.moderated_study_application_id
              }
              slot={selectedSlot}
              onSlotChange={setSelectedSlot}
              timezone={selectedTimezone}
              isFirstLoading={isFirstLoading}
              setIsFirstLoading={setIsFirstLoading}
              setHasAvailableSlots={setHasAvailableSlots}
              existingBookingId={existingBookingId}
            />
            <TimezoneChanger
              fontSize="md"
              lineHeight={6}
              fontWeight="medium"
              value={selectedTimezone}
              onChange={(e) => {
                setSelectedTimezone(e.target.value)
              }}
            />
          </Grid>
          <Divider marginBlock="4" />

          {!isFirstLoading && (
            <>
              {existingBooking && (
                <MyRescheduleBookingTimes
                  selectedSlot={selectedSlot}
                  selectedTimezone={selectedTimezone}
                  existingBooking={existingBooking}
                />
              )}

              <Flex justifyContent="flex-end" mt="4">
                {hasAvailableSlots ? (
                  <LegacyThemedButton
                    isLoading={isBooking}
                    isDisabled={!selectedSlot || previousSlotSelected}
                    onClick={() =>
                      selectedSlot &&
                      handleBooking(selectedSlot, selectedTimezone)
                    }
                  >
                    Book
                  </LegacyThemedButton>
                ) : (
                  <LegacyThemedButton onClick={navigateToThankYouPage}>
                    Continue
                  </LegacyThemedButton>
                )}
              </Flex>
            </>
          )}
        </VStack>
      </MotionBox>
    </AnimatePresence>
  )
}

type MyRescheduleBookingTimesProps = {
  selectedSlot: string | null
  selectedTimezone: string
  existingBooking: NonNullable<ModeratedStudyApplicationFlow["booking"]>
}

const MyRescheduleBookingTimes: React.FC<MyRescheduleBookingTimesProps> = ({
  selectedSlot,
  selectedTimezone,
  existingBooking,
}) => {
  const startTime = selectedSlot

  const endTime = React.useMemo(() => {
    if (!startTime || !existingBooking) return null

    const length =
      new Date(existingBooking.ends_at).valueOf() -
      new Date(existingBooking.starts_at).valueOf()

    const date = new Date(new Date(startTime).valueOf() + length)

    return date.toISOString()
  }, [startTime, existingBooking])

  const different =
    !!existingBooking &&
    (selectedTimezone !== existingBooking.participant_timezone ||
      startTime !== existingBooking.starts_at)

  return (
    <RescheduleBookingTimes
      selectedSlot={selectedSlot}
      startTime={startTime}
      endTime={endTime}
      timezone={selectedTimezone}
      different={different}
      booking={existingBooking}
    />
  )
}

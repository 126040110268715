import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { add, isFuture, isWithinInterval, sub } from "date-fns"
import React, { useMemo, useState } from "react"

import { LegacyThemedButton } from "Components/button/legacy-themed-button"
import { CalendarIcon } from "Icons/CalendarIcon"
import { MailIcon } from "Icons/MailIcon"
import { UserIcon } from "Icons/UserIcon"

import { LinkExternalIcon } from "Icons/LinkExternalIcon"
import { UserProfileIcon } from "Icons/UserProfileIcon"
import { getHumanizedTimePeriodFromUTC } from "Utilities/time"
import { useTrackJoinButtonClick } from "~/api/generated/usabilityhub-components"
import { CancelBooking } from "./CancelBooking"
import { IconDefinitionList } from "./IconDefinitionList"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"
import { useSessionTimeTitle } from "./useSessionTimeTitle"

export const ModeratedStudyJoinPage: React.FC = () => {
  const { application: moderatedStudyApplication } =
    useModeratedStudyApplicationContext()
  const { mutateAsync: trackJoinButton, isLoading } = useTrackJoinButtonClick({
    onSuccess: (_, variables) => {
      if (variables.body.missing_meeting_link) {
        setNotifiedHostAboutMissingLink(true)
      }
    },
  })
  const [notifiedHostAboutMissingLink, setNotifiedHostAboutMissingLink] =
    useState(false)

  const {
    moderated_study: moderatedStudy,
    applicant = null,
    panelist = null,
    booking = null,
  } = moderatedStudyApplication || {}

  const startsAt = useMemo(
    () => (booking?.starts_at ? new Date(booking.starts_at) : null),
    [booking]
  )

  const endsAt = useMemo(
    () => (booking?.ends_at ? new Date(booking.ends_at) : null),
    [booking]
  )

  const title = useSessionTimeTitle({ startsAt, endsAt })

  const name = applicant ? applicantName(applicant) : panelist?.preferred_name

  const dateAndTime = useMemo(() => {
    if (!startsAt) return ""
    return getHumanizedTimePeriodFromUTC(startsAt, endsAt ?? undefined)
  }, [startsAt, endsAt])

  const unlockedAt = useMemo(() => {
    if (!startsAt) return null
    return sub(startsAt, { minutes: 10 })
  }, [startsAt])

  const locked = !unlockedAt || Date.now() < unlockedAt.valueOf()
  const missingJoinLink = !moderatedStudy.location
  const firstHost = booking?.hosts[0]

  // NOTE: The join button is enabled even if there is no meeting URL so we can
  // track clicks
  const showAsDisabled = missingJoinLink

  const joinCall = () => {
    if (!isLoading && booking !== null) {
      trackJoinButton({
        pathParams: {
          moderatedStudyApplicationId:
            moderatedStudyApplication.moderated_study_application_id,
          moderatedStudyBookingId: booking.id,
        },
        body: {
          missing_meeting_link: missingJoinLink,
        },
      })
    }

    if (!moderatedStudy.location) return
    window.open(moderatedStudy.location, "_blank")
  }

  const now = new Date()
  const isWithin12Hours = startsAt
    ? isWithinInterval(startsAt, {
        start: now,
        end: add(now, { hours: 12 }),
      })
    : false
  const isBeforeStartTime = startsAt && isFuture(startsAt)

  return (
    <Flex direction="column" fontSize="md">
      <Heading fontSize="2xl" fontWeight="normal" color="green.500" mb={2}>
        {title}
      </Heading>
      <Text color="text.primary">
        Please ensure a stable internet connection for the best experience.
      </Text>

      <IconDefinitionList my={6}>
        <IconDefinitionList.Item icon={UserIcon} label="Name">
          <Text>{name}</Text>
        </IconDefinitionList.Item>
        {applicant && (
          <IconDefinitionList.Item icon={MailIcon} label="Email">
            <Text>{applicant.email}</Text>
          </IconDefinitionList.Item>
        )}
        <IconDefinitionList.Item icon={CalendarIcon} label="Date &amp; time">
          <Text>{dateAndTime}</Text>
        </IconDefinitionList.Item>
        <IconDefinitionList.Item
          icon={UserProfileIcon}
          label={booking && booking.hosts.length > 1 ? "Hosts" : "Host"}
        >
          <Stack>
            {booking &&
              booking.hosts.map(({ name, email }) => (
                <Text key={email}>
                  {name + (panelist ? "" : ` (${email})`)}
                </Text>
              ))}
          </Stack>
        </IconDefinitionList.Item>
      </IconDefinitionList>

      <Tooltip
        hasArrow
        rounded="md"
        isDisabled={!locked}
        label="You will be able to join the call 10 minutes before the session begins"
      >
        <LegacyThemedButton
          size="md"
          alignSelf="flex-start"
          rightIcon={<Icon as={LinkExternalIcon} />}
          isDisabled={locked}
          onClick={joinCall}
          opacity={showAsDisabled ? 0.4 : undefined}
          cursor={showAsDisabled ? "not-allowed" : undefined}
        >
          Join call
        </LegacyThemedButton>
      </Tooltip>

      {notifiedHostAboutMissingLink ? (
        <Alert status="warning" mt={6}>
          <AlertIcon />
          <Flex direction="column">
            <AlertTitle>Meeting link missing</AlertTitle>
            <AlertDescription>
              <Text>
                We have notified the host. Please{" "}
                <Button variant="link" onClick={() => window.location.reload()}>
                  refresh
                </Button>{" "}
                the page in a few minutes.
              </Text>
            </AlertDescription>
          </Flex>
        </Alert>
      ) : !locked && missingJoinLink && !panelist && firstHost ? (
        <Alert status="warning" mt={6}>
          <AlertIcon />
          <Flex direction="column">
            <AlertTitle>Meeting link missing</AlertTitle>
            <AlertDescription>
              Please contact {firstHost.name} –
              <Link href={`mailto:${firstHost.email}`}>{firstHost.email}</Link>.
            </AlertDescription>
          </Flex>
        </Alert>
      ) : null}

      {isBeforeStartTime && (
        <>
          <Divider my={4} borderColor="gray.300" />

          <CancelBooking
            moderatedStudyApplication={moderatedStudyApplication}
            isPanelist={!!panelist}
            isWithin12Hours={isWithin12Hours}
          />
        </>
      )}
    </Flex>
  )
}

const applicantName = (applicant: {
  preferred_name: string
  full_name: string
}) => {
  const preferredName =
    !!applicant.preferred_name &&
    applicant.preferred_name !== applicant.full_name
      ? ` (${applicant.preferred_name})`
      : ""

  return `${applicant.full_name}${preferredName}`
}

import { Box, BoxProps, Flex, Image } from "@chakra-ui/react"
import { Heading, IconButton } from "DesignSystem/components"
import { CloseIcon } from "Icons/CloseIcon"
import {
  HEADER_ANNOUNCEMENT_BANNER_HEIGHT,
  PAGE_CONTENT_MAX_WIDTH,
} from "UsabilityHub/constants/layoutConstants"
import { AnimatePresence, Variants, motion } from "framer-motion"
import React, { ReactNode } from "react"

type AnnouncementBannerProps = BoxProps & {
  /**
   * Whether the banner is visible
   */
  isOpen?: boolean

  /**
   * Banner title (optional)
   */
  bannerTitle?: ReactNode

  /**
   * Image to be displayed in the banner (optional)
   * Intended to be used with an import statement like:
   *   `import image from './image.png'`
   */
  image?: string

  /**
   * Children will be displayed in the description area of the banner.
   */
  children?: ReactNode

  /**
   * Actions to be displayed in the banner (optional)
   */
  actions?: ReactNode

  /**
   * Determines whether to display a close button to dismiss the banner.
   */
  onClose?: () => void

  /**
   * Don't show the close button.
   * (Make sure there's a way to dismiss the banner!)
   */
  hideCloseButton?: boolean

  /**
   * Don't show the image on narrow viewports.
   */
  hideImageOnNarrowViewports?: boolean
}

const variants: Variants = {
  out: { height: 0, opacity: 0 },
  in: { height: "auto", opacity: 1 },
}

/**
 * @example
 *   <AnnouncementBanner
 *     isOpen={isOpen}
 *     image={graphic}
 *     bg="ds.background.accent.yellow.subtle"
 *     actions={
 *       <>
 *         <Button variant="upgrade">Learn more</Button>
 *         <Button onClick={() => setOpen(false)}>Dismiss</Button>
 *       </>
 *     }
 *     onClose={() => setOpen(false)}
 *   >
 *     Experience the magic for yourself! Get $30 off your first panel order today
 *     and see why everyone is raving about our panel.
 *   </AnnouncementBanner>
 */
export const AnnouncementBanner: React.FC<AnnouncementBannerProps> = ({
  isOpen = true,
  bg = "ds.background.accent.teal.subtle",
  className,
  bannerTitle,
  image,
  children,
  actions,
  onClose,
  hideCloseButton,
  hideImageOnNarrowViewports,
  ...props
}) => {
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <Box
          as={motion.div}
          bg={bg}
          color="ds.text.default"
          px={[4, null, 12]}
          py={[4, null, 0]}
          pos="relative"
          variants={variants}
          initial="out"
          animate="in"
          exit="out"
          overflow="hidden"
          opacity={0.75}
          minH={HEADER_ANNOUNCEMENT_BANNER_HEIGHT}
          {...props}
          zIndex={1}
        >
          <Flex
            justify="start"
            flexDirection={["column", null, "row"]}
            align={["start", null, "center"]}
            maxW={PAGE_CONTENT_MAX_WIDTH}
            mx="auto"
            gap={[4, null, 8]}
          >
            {image && (
              <Box
                alignSelf={["start", null, "stretch"]}
                flex={["0 0 auto", null, "0 0 11.5rem"]}
                pos="relative"
                display={[
                  hideImageOnNarrowViewports ? "none" : "block",
                  null,
                  "grid",
                ]}
                placeContent="center"
              >
                <Image
                  src={image}
                  alignSelf="center"
                  objectFit="cover"
                  objectPosition="center"
                  pos={["static", null, "absolute"]}
                  w={["11.5rem", null, "100%"]}
                />
              </Box>
            )}
            <Flex align="center" flex={1} py={[0, null, 4]} gap={8}>
              {bannerTitle && (
                <Heading
                  as="h4"
                  textStyle="ds.display.secondary"
                  m={0}
                  width="100%"
                  textAlign={["initial", null, "center"]}
                  sx={{ textWrap: "balance" }}
                >
                  {bannerTitle}
                </Heading>
              )}
              {children && (
                <Box flex={1} textStyle="ds.heading.primary">
                  {children}
                </Box>
              )}
            </Flex>
            {actions && (
              <Flex py={[0, null, 4]} gap={2} whiteSpace="nowrap">
                {actions}
              </Flex>
            )}
          </Flex>
          {onClose && !hideCloseButton && (
            <IconButton
              aria-label="Dismiss"
              pos="absolute"
              size="compact"
              variant="secondary"
              right={2}
              top="50%"
              transform="translateY(-50%)"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
    </AnimatePresence>
  )
}

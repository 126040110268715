import { Flex, FlexProps } from "@chakra-ui/react"
import React, { PropsWithChildren, useRef } from "react"
import { useTabsContext } from "./Context"

type ListProps = FlexProps & {
  stretch?: boolean
}

export const List = ({
  children,
  stretch,
  sx = {},
  ...props
}: PropsWithChildren<ListProps>) => {
  const { size = "primary" } = useTabsContext()

  const wrapper = useRef<HTMLDivElement>(null)

  const switchTab = (direction: "left" | "right" | "home" | "end") => {
    if (!wrapper.current) return

    const tabs = Array.from(
      wrapper.current.querySelectorAll("[role='tab']:not([aria-disabled])")
    ) as HTMLElement[]
    const currentIndex = tabs.indexOf(document.activeElement as HTMLElement)

    if (currentIndex === -1) return

    const nextIndex = {
      left: currentIndex - 1,
      right: currentIndex + 1,
      home: 0,
      end: tabs.length - 1,
    }[direction]

    if (nextIndex < 0 || nextIndex >= tabs.length) return
    tabs[nextIndex].focus()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case "ArrowLeft":
        event.preventDefault()
        switchTab("left")
        break
      case "ArrowRight":
        event.preventDefault()
        switchTab("right")
        break
      case "Home":
        event.preventDefault()
        switchTab("home")
        break
      case "End":
        event.preventDefault()
        switchTab("end")
        break
    }
  }

  return (
    <Flex
      ref={wrapper}
      align="end"
      gap={stretch ? 0 : size === "secondary" ? 6 : 8}
      role="tablist"
      onKeyDown={handleKeyDown}
      {...props}
      sx={{
        ...sx,
        ...(stretch && {
          "[role='tab']": {
            flex: "1 1 0",
            "&::after": {
              opacity: 1,
            },
          },
        }),
      }}
    >
      {children}
    </Flex>
  )
}

import { Box, FormControl, FormErrorMessage, Textarea } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { LegacyThemedButton } from "Components/button/legacy-themed-button"

import { useScreenerQuestionAnswersContext } from "./AnsweredQuestionsContext"
import { QuestionText } from "./QuestionText"
import { ParticipantScreenerQuestion, ScreenerQuestionAnswer } from "./types"

const LongTextScreenerQuestionAnswer = Yup.object().shape({
  text: Yup.string().required("Please answer to continue"),
})

type LongTextScreenerQuestionAnswerValues = Yup.InferType<
  typeof LongTextScreenerQuestionAnswer
>

interface LongTextScreenerQuestionsAnswerFormProps {
  question: ParticipantScreenerQuestion
  defaultValue: string
  onSubmit: (values: ScreenerQuestionAnswer) => void
}

export function LongTextScreenerQuestionsAnswerForm({
  question,
  defaultValue,
  onSubmit: onExternalSubmit,
}: LongTextScreenerQuestionsAnswerFormProps) {
  const { goToNextQuestion, setIsInvalidAnswer } =
    useScreenerQuestionAnswersContext()

  const {
    handleSubmit,
    register,
    watch,
    formState: { isValid, errors },
  } = useForm<LongTextScreenerQuestionAnswerValues>({
    defaultValues: {
      text: defaultValue,
    },
    resolver: yupResolver(LongTextScreenerQuestionAnswer),
  })

  const onSubmit: SubmitHandler<LongTextScreenerQuestionAnswerValues> = (
    values
  ) => {
    onExternalSubmit({
      screener_question_id: question.id,
      type: "long_text",
      text: values.text,
    })
  }

  useEffect(() => {
    const subscription = watch(() => {
      void handleSubmit(onSubmit)()
    })

    return () => subscription.unsubscribe()
  })

  useEffect(() => {
    setIsInvalidAnswer(!isValid)
  }, [isValid])

  return (
    <div>
      <QuestionText>{question.text}</QuestionText>
      <Box mt={5}>
        <form
          onSubmit={handleSubmit((values) => {
            onSubmit(values)
            goToNextQuestion()
          })}
        >
          <FormControl isInvalid={!!errors.text}>
            <Textarea {...register("text")} bgColor="white" autoFocus />
            {errors.text && (
              <FormErrorMessage>{errors.text.message}</FormErrorMessage>
            )}
          </FormControl>
          <LegacyThemedButton mt={8} type="submit" isDisabled={!isValid}>
            Continue
          </LegacyThemedButton>
        </form>
      </Box>
    </div>
  )
}

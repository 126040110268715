import React, { createContext, PropsWithChildren, useContext } from "react"
import { TabSize } from "./types"

type TabsContext = {
  contextId: string
  selected: string | null
  isLazy: boolean
  switchOnFocus: boolean
  size: TabSize
  onChange: (selected: string) => void
  registerTab: (value: string) => void
  unregisterTab: (value: string) => void
}

const TabsContext = createContext<TabsContext>({
  contextId: "",
  selected: null,
  isLazy: false,
  switchOnFocus: true,
  size: "primary",
  onChange: () => {},
  registerTab: () => {},
  unregisterTab: () => {},
})

export const TabsProvider = ({
  children,
  ...context
}: PropsWithChildren<TabsContext>) => {
  return <TabsContext.Provider value={context}>{children}</TabsContext.Provider>
}

export const useTabsContext = () => useContext(TabsContext)

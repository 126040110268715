import { centsToDollars } from "Utilities/currency"
import { Plan } from "~/api/generated/usabilityhubSchemas"

// All purchases are currently in USD
const DEFAULT_CURRENCY = "USD"

const trackWithGoogle = (
  eventName: string,
  eventParams?: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams
) => {
  gtag("event", eventName, eventParams)
  if (process.env.DEBUG) {
    console.debug("trackWithGoogleGtagJs", eventName, eventParams)
  }
}

/**
 * Track a purchase event. This is a special event type in GA4 and will flow through to built-in
 * Monetization reports.
 * We use the `itemListName` as the closest match to differentiate the source of the item being
 * purchased. For example for credits, these can be a bulk purchase, or purchased as part of placing
 * an order.
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
 */
const trackRevenueWithGoogle = (value: number, items: Gtag.Item[]) => {
  trackWithGoogle("purchase", {
    items,
    value,
    currency: DEFAULT_CURRENCY,
  })
}

export const creditsPurchasedGoogle = (
  itemListName: string,
  priceInCents: number
) => {
  const value = Number(centsToDollars(priceInCents).toFixed(2))
  trackRevenueWithGoogle(value, [
    { item_name: "credits", item_list_name: itemListName },
  ])
  // Continue sending old 'purchased_credits' event for now. It's unclear in GA4 reports whether it's
  // possible to differentiate between purchase events by their item name and variant. We may remove
  // this second event in future if it's possible to do everything we need to using the above
  // 'purchase' event.
  trackWithGoogle("purchased_credits")
}

export const responsesOrderedGoogle = () => {
  trackWithGoogle("ordered_responses")
}

export const signedUpGoogle = (label: string) => {
  trackWithGoogle("signup", { event_label: label })
}

export const subscriptionCreatedGoogle = (plan: Plan, value: number) => {
  trackRevenueWithGoogle(value, [
    { item_name: "subscription", item_variant: plan.unique_id },
  ])
  // Continue sending old 'subscribed' event for now. It's unclear in GA4 reports whether it's
  // possible to differentiate between purchase events by their item name and variant. We may remove
  // this second event in future if it's possible to do everything we need to using the above
  // 'purchase' event.
  trackWithGoogle("subscribed")
}

export const testCreatedGoogle = () => {
  trackWithGoogle("created_test")
}

export const thirdPartyResponsesOrderedGoogle = () => {
  trackWithGoogle("ordered_third_party_responses")
}

export const interviewCreatedGoogle = () => {
  trackWithGoogle("interview_created")
}

export const interviewOrderPlacedGoogle = () => {
  trackWithGoogle("interview_order_placed")
}

export const integrationConnectedGoogle = (
  type: "Google calendar" | "Zoom"
) => {
  trackWithGoogle("integration_connected", {
    type,
  })
}

export const interviewVideoUploadedManuallyGoogle = () => {
  trackWithGoogle("interview_video_uploaded_manually")
}

export const interviewTranscriptRequestedGoogle = () => {
  trackWithGoogle("interview_transcript_requested")
}

export const interviewApplicationReceivedGoogle = () => {
  trackWithGoogle("interview_application_received")
}

export const interviewBookingReceivedGoogle = () => {
  trackWithGoogle("interview_booking_received")
}

export const interviewBookingCompletedGoogle = () => {
  trackWithGoogle("interview_booking_completed")
}

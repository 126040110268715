import { List } from "./List"
import { Panel } from "./Panel"
import { Panels } from "./Panels"
import { Root } from "./Root"
import { Tab } from "./Tab"

/**
 * Use <Tabs.Root> to wrap your tabs.
 * The selected tab can be controlled or uncontrolled. If no tab is selected,
 * the first tab will be selected by default.
 *
 * @prop {isLazy} - If true, the tabs will be mounted and unmounted as they are selected.
 * @prop {selected} - The value of the selected tab.
 * @prop {onChange} - The callback function that is called when the selected tab changes.
 * @prop {switchOnFocus} - If true (the default), the tabs will switch when the user focuses on a tab.
 *
 * @example
 * <Tabs.Root>
 *   <Tabs.List>
 *     <Tabs.Tab>Atreides</Tabs.Tab>
 *     <Tabs.Tab>Harkonnen</Tabs.Tab>
 *     <Tabs.Tab>Ordos</Tabs.Tab>
 *   </Tabs.List>
 *   <Tabs.Panels>
 *     <Tabs.Panel>Atreides</Tabs.Panel>
 *     <Tabs.Panel>Harkonnen</Tabs.Panel>
 *     <Tabs.Panel>Ordos</Tabs.Panel>
 *   </Tabs.Panels>
 * </Tabs.Root>
 */
export const Tabs = {
  Root,
  List,
  Tab,
  Panels,
  Panel,
}

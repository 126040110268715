import { Box, BoxProps, useControllableState, useId } from "@chakra-ui/react"
import React, { PropsWithChildren, useRef } from "react"
import { TabsProvider } from "./Context"
import { TabSize } from "./types"

type BaseRootProps = {
  selected?: string
  isLazy?: boolean
  switchOnFocus?: boolean
  size?: TabSize
  onChange?: (selected: string) => void
}

type RootProps = Omit<BoxProps, keyof BaseRootProps> & BaseRootProps

export const Root = ({
  selected,
  onChange,
  isLazy = false,
  switchOnFocus = true,
  size = "primary",
  children,
  ...props
}: PropsWithChildren<RootProps>) => {
  const contextId = useId()

  const [selectedTab, setSelectedTab] = useControllableState<string | null>({
    value: selected,
    onChange,
    defaultValue: null,
  })

  const tabs = useRef<string[]>([])

  const registerTab = (value: string) => {
    tabs.current.push(value)
    if (tabs.current.length === 1 && !selectedTab) {
      setSelectedTab(value)
    }
  }

  const unregisterTab = (value: string) => {
    tabs.current = tabs.current.filter((tab) => tab !== value)
  }

  return (
    <TabsProvider
      contextId={contextId}
      selected={selectedTab}
      onChange={setSelectedTab}
      isLazy={isLazy}
      size={size}
      switchOnFocus={switchOnFocus}
      registerTab={registerTab}
      unregisterTab={unregisterTab}
    >
      <Box {...props}>{children}</Box>
    </TabsProvider>
  )
}

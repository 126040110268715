import {
  IconButton as ChakraIconButton,
  IconButtonProps,
  forwardRef,
  useStyleConfig,
} from "@chakra-ui/react"
import { IconButtonDS } from "app/frontend/design-system/chakra-theme/components/IconButtonDS"
import React from "react"
import { selectedStyles } from "./Button"

type IsSelectedProp = {
  isSelected?: boolean
}

type Props = Omit<IconButtonProps, "variant" | "size"> &
  IsSelectedProp & {
    variant?: keyof NonNullable<typeof IconButtonDS.variants>
    size?: keyof NonNullable<typeof IconButtonDS.sizes>
  }

/**
 * - Use `variant` and `size` props to control appearance.
 * - Use `icon={<ImportedIcon />}` to choose an icon.
 * - Use the `isSelected` prop to access the selected state.
 *   - State management for selected buttons is handled by the consumer (similar to `isDisabled`).
 */
export const IconButton = forwardRef<Props, "button">((props, ref) => {
  const { variant, size, isSelected, ...rest } = props

  const styles = useStyleConfig("IconButtonDS", { variant, size })

  const styleOverrides = isSelected ? selectedStyles() : undefined

  return (
    <ChakraIconButton ref={ref} __css={styles} {...rest} sx={styleOverrides} />
  )
})

IconButton.displayName = "IconButton"

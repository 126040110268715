import { Link } from "@chakra-ui/react"
import { appendQueryString, stringify } from "Utilities/query-string"
import React, { AnchorHTMLAttributes } from "react"

export const NEW_TAB_ATTRIBUTES: Readonly<Record<string, string>> = {
  target: "_blank",
  rel: "noopener noreferrer",
}

type NoHrefProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">

interface MailToAnchorProps extends NoHrefProps {
  email: string
  name?: string
  subject?: string
  body?: string
}

const mailToHref = ({
  email,
  name,
  subject,
  body,
}: MailToAnchorProps): string => {
  const addrSpec = name === undefined ? email : `"${name}"<${email}>`
  return appendQueryString(`mailto:${addrSpec}`, stringify({ subject, body }))
}

export const MailToAnchor: React.FC<
  React.PropsWithChildren<MailToAnchorProps>
> = (props) => {
  const { email, children, name, subject, body, className, ...other } = props
  const content = children || email
  return (
    <Link href={mailToHref({ email, name, subject, body })} {...other}>
      {content}
    </Link>
  )
}

type NewTabAnchorProps = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  "target" | "rel"
>

export const NewTabAnchor: React.FC<
  React.PropsWithChildren<NewTabAnchorProps>
> = ({ children, className, ...other }) => (
  <Link {...NEW_TAB_ATTRIBUTES} {...other}>
    {children}
  </Link>
)

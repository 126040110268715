import * as Sentry from "@sentry/browser"
import { getEnvState } from "JavaScripts/state"
import { token } from "Services/rails-csrf-token"
import { omit } from "lodash"
import { UsabilityhubContext } from "./usabilityhub-context"

const baseUrl = ""

export type ErrorWrapper<TError> =
  | TError
  | { status: number | "unknown"; payload: { message: string } }

export type UsabilityhubFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams,
> = {
  url: string
  method: string
  body?: TBody
  headers?: THeaders
  queryParams?: TQueryParams
  pathParams?: TPathParams
} & UsabilityhubContext["fetcherOptions"]

export async function usabilityhubFetch<
  TData,
  TError,
  TBody extends {} | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
}: UsabilityhubFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
>): Promise<TData> {
  const { CLIENT_VERSION } = getEnvState()

  let response: Response
  try {
    let processedBody: FormData | string | undefined
    let contentType: string | undefined = "application/json"

    // Everything is application/json unless multipart/form-data is specifically requested.
    // We use this to upload files such as user avatars.
    // The OpenAPI schema has to have the right content type for it to work, but I couldn't find
    // an obvious way to infer it here. So for now you have to set the header manually on the mutation.
    if (headers && headers["Content-Type"] === "multipart/form-data" && body) {
      const formData = new FormData()

      Object.entries(body).forEach(([name, value]) => {
        if (value instanceof File) {
          formData.append(name, value)
        } else if (Array.isArray(value)) {
          if (url === "/api/recordings") { // URL is a path here
            for (const v of value) {
              formData.append(`${name}[]`, v.toString())
            }
          } else {
            // TODO: [MOT-1332] Remove this after identifying potential (mis)uses.
            // Identify any areas of the app that may be sending arrays as strings.
            // We can start converting these to arrays in OpenAPI.
            Sentry.captureMessage("Array value being sent as string", {extra: {url}});
            formData.append(name, value?.toString() ?? "")
          }
        } else if (value !== undefined) {
          formData.append(name, value?.toString() ?? "")
        }
      })

      processedBody = formData
      // The browser will set the content type automatically for FormData
      // It adds the boundary to the content type, so we can't set it manually
      contentType = undefined
    } else if (body) {
      processedBody = JSON.stringify(body)
    }

    response = await window.fetch(
      `${baseUrl}${resolveUrl(url, queryParams, pathParams)}`,
      {
        method: method.toUpperCase(),
        body: processedBody,
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": token!,
          "X-Requested-With": "XMLHttpRequest",
          "X-Client-Version": CLIENT_VERSION,
          ...omit(headers, "Content-Type"),
          ...(contentType ? { "Content-Type": contentType } : {}),
        },
      }
    )
  } catch (e) {
    throw {
      status: "unknown" as const,
      payload: {
        message:
          e instanceof Error ? `Network error (${e.message})` : "Network error",
      },
    }
  }

  if (!response.ok) {
    let error: ErrorWrapper<TError>
    error = {
      status: response.status,
      payload: await response.json(),
    }
    throw error
  }

  if (response.headers.get("content-type")?.includes("json")) {
    return await response.json()
  } else {
    // If this is not a JSON response, assume it's a blob and cast it to TData
    return (await response.blob()) as unknown as TData
  }
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {}
) => {
  let query = new URLSearchParams(queryParams).toString()
  if (query) query = `?${query}`
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query
}

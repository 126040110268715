import { Config } from "@js-from-routes/axios"
import { FetchOptions } from "@js-from-routes/client"
import _axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from "axios"

import { token } from "Services/rails-csrf-token"
import { reportErrorToSentry } from "Utilities/error"

/**
 *
 * JS from routes configuration
 */

// Noop on (de)serialization since we are handling this
// inconsistently between the frontend and the backend:
// https://js-from-routes.netlify.app/client/#disabling-case-conversion
const noop = <T>(val: T): T => val
Config.deserializeData = noop
Config.serializeData = noop
Config.fetch = async function fetch(args: FetchOptions) {
  const { responseAs, signal, ...options } = args
  const responseType =
    responseAs === "response"
      ? undefined
      : (responseAs.toLowerCase() as ResponseType)
  const config: AxiosRequestConfig = {
    responseType,
    signal: signal ?? undefined,
    ...options,
  }

  try {
    return await _axios.request(config)
  } catch (error) {
    if (isTooManyRequestsError(error)) {
      alert("You have been rate limited. Please wait a minute, and try again.")
    }

    // Fall back to default axios behaviour for all other unsuccessful response codes
    throw error
  }
}

/**
 * @deprecated in favour of JS from routes
 * Axios configuration
 */
const axios = _axios.create({
  headers: {
    common: {
      "X-CSRF-Token": token ?? undefined,
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  },
})

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Don't do anything for successful responses
    return response
  },
  async (error: AxiosError) => {
    if (isTooManyRequestsError(error)) {
      alert("You have been rate limited. Please wait a minute, and try again.")
    }

    // Fall back to default axios behaviour for all other unsuccessful response codes
    return Promise.reject(error)
  }
)

export { axios }

// Axios doesn't have specific types for a server error response; AxiosError encompasses all types
// of errors including those that happen before a request is sent. Add our own AxiosResponseError
// as a special case of AxiosError that will always have a 'response' property present.
interface AxiosResponseError<T = any> extends AxiosError {
  response: AxiosResponse<T>
}

// NOTE: If you're looking to get an error message from the response,
// see: isAxiosErrorWithMessage
export function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError === true
}

export function isAxiosErrorWithMessage(
  error: any
): error is AxiosResponseError<{ message: string }> {
  if (
    error.isAxiosError !== true ||
    (error as AxiosError).response === undefined ||
    (error as AxiosError).response === null
  )
    return false

  if (typeof error.response.data.message !== "string") {
    try {
      // Throw because we want the stacktrace
      throw new Error("error response did not contain `message`")
      // Immediately catch so we don't disrupt other error handling
    } catch (e) {
      reportErrorToSentry(e)
    }
  }

  return true
}

/**
 * @deprecated API should return a message and then you can use
 * isAxiosErrorWithMessage to correctly set the type
 */
export function isDeprecatedAxiosError<T>(error: any): error is AxiosError<T> {
  return error.isAxiosError === true
}

export function isBadRequestError<T = any>(
  error: any
): error is AxiosResponseError<T> {
  // We don't yet have consistency on the back-end. Handle both 422 and 400 responses here until
  // we standardize on one of those response codes.
  return (
    isDeprecatedAxiosError<T>(error) &&
    (error.response?.status === 422 || error.response?.status === 400)
  )
}

export function isNotFoundError<T = any>(
  error: any
): error is AxiosResponseError<T> {
  return isDeprecatedAxiosError<T>(error) && error.response?.status === 404
}

function isTooManyRequestsError<T = any>(
  error: any
): error is AxiosResponseError<T> {
  return isDeprecatedAxiosError<T>(error) && error.response?.status === 429
}

export type ProgressHandler = (progress: number) => void

/* Usage:
 *
 * axios.post(url, data, {
 *   onUploadProgress: progressPercentage((progress) => {
 *     console.debug(`Upload is ${progress * 100}% completed`)
 *   })
 * })
 *
 */
export function progressPercentage(handler: ProgressHandler) {
  return (event: ProgressEvent) => {
    handler(event.loaded / event.total)
  }
}

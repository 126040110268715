import {
  Flex,
  Link,
  Text,
  TextProps,
  UseDisclosureReturn,
} from "@chakra-ui/react"
import { Badge, Heading } from "DesignSystem/components"
import LWTLaunchIllustration1 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-1.png"
import LWTLaunchIllustration2 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-2.png"
import LWTLaunchIllustration3 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-3-alt.png"
import LWTLaunchIllustration4 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-4.png"
import LWTLaunchIllustration5 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-5.png"
import {
  OnboardingFlow,
  OnboardingFlowPage,
} from "Shared/components/OnboardingFlow/OnboardingFlow"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"
import { Link as RoutedLink } from "react-router-dom"

const OnboardingContentWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Flex flexDirection="column" gap={4} alignItems="flex-start">
    <Badge label="New feature" />
    {children}
  </Flex>
)

const OnboardingHeading: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Heading as="h3" textStyle="ds.display.primary">
    {children}
  </Heading>
)
const OnboardingText: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  ...props
}) => (
  <Text textStyle="ds.paragraph.emphasized" {...props}>
    {children}
  </Text>
)

const PageOne: OnboardingFlowPage = {
  content: (
    <OnboardingContentWrapper>
      <OnboardingHeading>Live website tests are here!</OnboardingHeading>
      <OnboardingText>
        Tests may ask you to complete tasks, answer questions, or give feedback
        on real, live websites.
      </OnboardingText>
    </OnboardingContentWrapper>
  ),
  imageSrc: LWTLaunchIllustration1,
  nextButtonLabel: "Next",
}

const PageTwo: OnboardingFlowPage = {
  content: (
    <OnboardingContentWrapper>
      <OnboardingHeading>How does it work?</OnboardingHeading>
      <OnboardingText as="div" display="flex" flexDirection="column" gap={4}>
        <Text>
          {`You\u2019ll`} be directed to an external website during the test.
          Instructions and questions will be shown in a small, floating window.
        </Text>

        <Text>
          Your screen will be recorded as you complete tasks and answer
          follow-up questions.
        </Text>
      </OnboardingText>
    </OnboardingContentWrapper>
  ),
  imageSrc: LWTLaunchIllustration2,
  nextButtonLabel: "Next",
}

const PageThree: OnboardingFlowPage = {
  content: (
    <OnboardingContentWrapper>
      <OnboardingHeading>What do I need to participate?</OnboardingHeading>
      <OnboardingText>
        {`You\u2019ll`} need a desktop or laptop with Chrome, Edge or another
        Chromium-based browser. Tests involving camera and microphone recording
        will require a webcam.
      </OnboardingText>
    </OnboardingContentWrapper>
  ),
  imageSrc: LWTLaunchIllustration3,
  nextButtonLabel: "Next",
}

const PageFour: OnboardingFlowPage = {
  content: (
    <OnboardingContentWrapper>
      <OnboardingHeading>How is my privacy protected?</OnboardingHeading>
      <OnboardingText>
        Your privacy is important to us. We {`don\u2019t`} disclose any of your
        personal information. You can keep your privacy safe by not sharing any
        personal information while testing.
      </OnboardingText>
    </OnboardingContentWrapper>
  ),
  imageSrc: LWTLaunchIllustration4,
  nextButtonLabel: "Next",
}

const PageFive: OnboardingFlowPage = {
  content: (
    <OnboardingContentWrapper>
      <OnboardingHeading>Unlock more rewards</OnboardingHeading>
      <OnboardingText>
        We understand that recorded tests require a little more from
        participants. {`That\u2019s`} why we offer a higher incentive to
        participate in these tests.
      </OnboardingText>
      <Link
        as={RoutedLink}
        to={ROUTES.SETTINGS.NOTIFICATIONS.path}
        target="_blank"
      >
        Opt out of tests like these
      </Link>
    </OnboardingContentWrapper>
  ),
  imageSrc: LWTLaunchIllustration5,
  nextButtonLabel: "Got it!",
}

export const LiveWebsiteTestingLaunchOnboarding: React.FC<{
  disclosure: UseDisclosureReturn
  onFinish: () => void
}> = ({ disclosure, onFinish }) => {
  return (
    <OnboardingFlow
      {...disclosure}
      onFinish={onFinish}
      pages={[PageOne, PageTwo, PageThree, PageFour, PageFive]}
    />
  )
}

import { Box, Text } from "@chakra-ui/react"
import { motion } from "framer-motion"
import React, { PropsWithChildren, useEffect } from "react"
import { useTabsContext } from "./Context"

type TabProps = {
  value: string
  icon?: React.ReactNode
  count?: number
  isDisabled?: boolean
}

const tabSizeStyles = {
  primary: {
    py: 4,
    textStyle: "ds.interface.large",
  },
  secondary: {
    py: 2,
    lineHeight: "1rem",
    textStyle: "ds.interface.medium",
  },
}

export const Tab = ({
  icon,
  value,
  count,
  children,
  isDisabled = false,
}: PropsWithChildren<TabProps>) => {
  const {
    contextId,
    selected,
    size,
    switchOnFocus,
    onChange,
    registerTab,
    unregisterTab,
  } = useTabsContext()
  const isSelected = selected === value

  useEffect(() => {
    if (isDisabled) return

    registerTab(value)
    return () => unregisterTab(value)
  }, [registerTab, unregisterTab, value, isDisabled])

  return (
    <Box
      as="button"
      type="button"
      role="tab"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={2}
      px={0}
      minW={0}
      {...tabSizeStyles[size]}
      color="ds.text.default"
      tabIndex={isSelected ? 0 : -1}
      aria-selected={isSelected}
      aria-controls={`${contextId}:tabpanel:${value}`}
      aria-disabled={isDisabled || undefined}
      id={`${contextId}:tab:${value}`}
      onClick={() => !isDisabled && onChange(value)}
      onFocus={() => switchOnFocus && !isDisabled && onChange(value)}
      sx={{
        ".chakra-icon": {
          color: "ds.icon.subtle",
        },
      }}
      _after={{
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: "2px",
        backgroundColor: "ds.border.default",
        opacity: 0,
      }}
      _hover={{
        _after: {
          opacity: 1,
        },
      }}
      _focus={{
        _after: {
          opacity: 1,
        },
      }}
      _selected={{
        color: "ds.text.selected",

        ".chakra-icon": {
          color: "ds.icon.selected",
        },
      }}
      _disabled={{
        color: "ds.text.disabled",
        cursor: "not-allowed",
        pointerEvents: "none",

        ".chakra-icon": {
          color: "ds.icon.disabled",
        },
      }}
    >
      {icon}
      <Text isTruncated minW={0}>
        {children}
      </Text>
      {count !== undefined && (
        <Text color={isSelected ? "inherit" : "ds.text.subtle"}>{count}</Text>
      )}

      {isSelected && (
        <Box
          as={motion.div}
          position="absolute"
          layoutId={`${contextId}:tab-indicator`}
          bottom={0}
          left={0}
          right={0}
          height="2px"
          backgroundColor="ds.border.selected"
        />
      )}
    </Box>
  )
}

import { Platform } from "Types"

import { DeviceType } from "./deviceType"

export enum ReviewStatus {
  Accepted = 0,
  Rejected = 1,
  AutoAccepted = 2,
  AcceptedLowQuality = 3,
  RejectedLegacy = 4,
  Void = 5,
}

const PARTICIPANT_DELETION_REASONS = [
  "confusing_test",
  "inappropriate_test",
  "incorrect_test_language",
  "incorrect_profile_language",
  "skipped",
  "technical_problem",
  "images_failed_to_load",
  "inactive",
  "canceled_to_start_another_response",
  "test_requested_personal_information",
  "test_sent_me_offsite",
  "browser_not_supported",
  // Reasons for recording issues
  "recording_permission_denied",
  "recording_upload_failed",
  "recording_premature_stop",
  "recording_failed",
  // External study issues
  "no_completion_code",
  "participant_id_not_requested",
] as const

export type ParticipantDeletionReason =
  (typeof PARTICIPANT_DELETION_REASONS)[number]

export const isParticipantDeletionReason = (
  reason: ResponseDeletionReason
): reason is ParticipantDeletionReason => {
  return (PARTICIPANT_DELETION_REASONS as Readonly<string[]>).includes(reason)
}

export type PanelistPublicDeletionReason =
  | ParticipantDeletionReason
  | AutomaticDeletionReason

export type OwnerDeletionReason =
  | "weak_language"
  | "spam"
  | "malicious"
  | "low_effort"
  | "did_not_speak"
  | "recording_quality"
  | "unspecified"

type AutomaticDeletionReason =
  | "disconnected"
  | "malicious"
  | "timed_out"
  | "too_fast_response"

export type ResponseDeletionReason =
  | ParticipantDeletionReason
  | OwnerDeletionReason
  | AutomaticDeletionReason

export interface ResponseDemographicProfile {
  age: number | null
  country: string | null
  location?: {
    type: "country" | "state" | "city"
    id: string
  }
  demographic_attribute_option_ids: number[]
}

export interface Response {
  automated_review_status: ReviewStatus | null
  deleted_at: string | null
  deletion_reason: ResponseDeletionReason
  device_type: DeviceType | null
  duration_ms: number | null
  estimated_duration_ms: number | null
  id: number
  order_id: number | null
  platform: Platform | null
  review_status: ReviewStatus | null
  submitted_at: string | null
  third_party_order_id: number | null
  usability_test_id: number
  user_id: number | null
  query_parameters: Readonly<Record<string, string>> | null
  response_demographic_profile?: ResponseDemographicProfile | null
  cint_respondent_id: string | null
}

import { useDisclosure } from "@chakra-ui/react"
import { Button } from "DesignSystem/components"
import LWTLaunchIllustration1 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-1.png"
import LWTLaunchIllustration2 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-2.png"
import LWTLaunchIllustration3 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-3-alt.png"
import LWTLaunchIllustration4 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-4.png"
import LWTLaunchIllustration5 from "Images/app-illustrations/usercrowd-LWT/LWT-launch-5.png"
import LWTAnnouncementBannerImg from "Images/app-illustrations/usercrowd-LWT/LWT-post-launch-banner.png"
import { AnnouncementBanner } from "Shared/components/AnnouncementBanner"
import { useDismissibleNotice } from "Shared/hooks/useDismissibleNotice"
import { USERCROWD_NAVBAR_HEIGHT } from "UserCrowd/constants"
import React from "react"
import { Helmet } from "react-helmet"
import { LiveWebsiteTestingLaunchOnboarding } from "./LiveWebsiteTestingLaunchOnboarding"

export const LiveWebsiteTestingLaunchBanner: React.FC = () => {
  const bannerNotice = useDismissibleNotice("live_website_testing_launch")
  const onboardingDisclosure = useDisclosure()

  if (bannerNotice.isLoading || !bannerNotice.isOpen) return null

  return (
    <>
      <Helmet>
        <link rel="preload" href={LWTLaunchIllustration1} as="image" />
        <link rel="preload" href={LWTLaunchIllustration2} as="image" />
        <link rel="preload" href={LWTLaunchIllustration3} as="image" />
        <link rel="preload" href={LWTLaunchIllustration4} as="image" />
        <link rel="preload" href={LWTLaunchIllustration5} as="image" />
      </Helmet>
      <AnnouncementBanner
        image={LWTAnnouncementBannerImg}
        bannerTitle="Good news! Now you have even more ways to earn—take part in live website tests today!"
        actions={
          <>
            <Button variant="primary" onClick={onboardingDisclosure.onOpen}>
              Learn more
            </Button>
            <Button onClick={bannerNotice.dismiss}>Dismiss</Button>
          </>
        }
        bg="ds.background.accent.yellow.subtle"
        mt={USERCROWD_NAVBAR_HEIGHT}
        mb={`calc(-1 * ${USERCROWD_NAVBAR_HEIGHT})`}
        hideCloseButton
        hideImageOnNarrowViewports
      />
      <LiveWebsiteTestingLaunchOnboarding
        disclosure={onboardingDisclosure}
        onFinish={bannerNotice.dismiss}
      />
    </>
  )
}

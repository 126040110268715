import { intersection } from "lodash"

import { PrototypeResponseState } from "JavaScripts/types/figma-file-flow"
import { FigmaFileVersionAnswer } from "JavaScripts/types/figma-file-version-answer"
import {
  ParticipantResponse,
  RawParticipantResponse,
  RawParticipantUsabilityTest,
  Response,
  ResponseAnswer,
  ResponseDemographicProfile,
  ResponseSection,
  TestLogicBehaviour,
  Unpersisted,
  UnpersistedResponseSection,
  UsabilityTestSection,
  UsabilityTestSectionOrQuestion,
  UsabilityTestSectionQuestion,
  UsabilityTestSectionType,
} from "Types"
import {
  isCheckboxQuestion,
  isRadioQuestion,
} from "Utilities/usability-test-section-question"
import type {
  DemographicAttribute,
  RecruitmentLink,
} from "~/api/generated/usabilityhubSchemas"

export enum ResponsePhase {
  DemographicQuestions,
  TakingTest,
  Complete,
}

export interface ResponseState {
  phase: ResponsePhase
  question: UsabilityTestSectionQuestion | null
  usabilityTestSection: UsabilityTestSection | null
  responseSection: UnpersistedResponseSection | null
}

export function isInProgress(response: {
  panelist_deletion_reason: unknown
  submitted_at: unknown
}) {
  // NOTE: This works because any deletion reasons _not included_ in
  // PanelistPublicDeletionReason occur _after_ the response is submitted.
  return (
    response.panelist_deletion_reason === null && response.submitted_at === null
  )
}

export function isPreview(response: Response): boolean {
  return response.id === null
}

export function isRecruited(response: Response): boolean {
  return !isPanelOrdered(response) && !isThirdPartyOrdered(response)
}

export function isPanelOrdered(response: { order_id: number | null }): boolean {
  return response.order_id !== null
}

export function isThirdPartyOrdered(response: Response): boolean {
  return response.third_party_order_id !== null
}

export function isDeleted(response: Response): boolean {
  return response.deleted_at !== null
}

export function isSubmitted(response: Response): boolean {
  return response.submitted_at !== null
}

export function isTaskComplete(
  responseSection: Pick<ResponseSection, "task_duration_ms"> | null
): boolean {
  return responseSection !== null && responseSection.task_duration_ms !== null
}

export function isTaskStarted(
  responseSection: Pick<ResponseSection, "instructions_duration_ms"> | null
): boolean {
  return (
    responseSection !== null &&
    responseSection.instructions_duration_ms !== null
  )
}

export function sectionStartTime(
  responseSection: UnpersistedResponseSection | null
): number {
  if (responseSection === null) throw new TypeError("responseSection === null")
  return responseSection._startTime
}

export function cardDragStartTime(
  responseSection: UnpersistedResponseSection | null,
  cardId: number
): number {
  if (responseSection === null) throw new TypeError("responseSection === null")
  if (responseSection.cards_sort_time === null) return 0
  return responseSection.cards_sort_time[cardId].start_time_ms
}

export function cardsSortTime(
  responseSection: UnpersistedResponseSection | null
) {
  if (responseSection === null) throw new TypeError("responseSection === null")
  return responseSection.cards_sort_time
}

export function cardSortTime(
  responseSection: UnpersistedResponseSection | null,
  cardId: number
): number {
  if (responseSection === null) throw new TypeError("responseSection === null")
  if (responseSection.cards_sort_time === null) return 0

  const cardSortTime = responseSection.cards_sort_time[cardId]
  if (cardSortTime === undefined || cardSortTime.end_time_ms === undefined)
    return 0

  return cardSortTime.end_time_ms - cardSortTime.start_time_ms
}

// Flattening these response parameters is not ideal but the variety of response types we have in calling code makes
// it hard to avoid:
// - Readonly<UnpersistedResponseSection> | null
// - Partial<Omit<ResponseSection, "usability_test_section_id">>;
// - Readonly<UnpersistedResponseSection> | undefined
export function requiresTaskFlowSuccessAcknowledged(
  section: UsabilityTestSection,
  totalDurationMs: number | null | undefined,
  figmaFileVersionAnswer: FigmaFileVersionAnswer | null | undefined,
  _taskFlowSuccessAcknowledged: boolean | null | undefined
): boolean {
  if (section.prototype_type === "task_flow") {
    return !!(
      // short circuit if the section is already completed
      (
        !totalDurationMs &&
        // required if the test was configured to acknowledge success, the tester reached the goal, and has not yet acknowledged
        section.figma_file_flow?.show_success_screen &&
        figmaFileVersionAnswer?.task_result === "completed" &&
        !_taskFlowSuccessAcknowledged
      )
    )
  }
  return false
}

function areRequestedDemographicsPresent(
  demographicProfile: ResponseDemographicProfile | null,
  recruitmentLink: RecruitmentLink,
  demographics: DemographicAttribute[]
): boolean {
  if (!demographicProfile) return false

  if (recruitmentLink.capture_age && demographicProfile.age === null) {
    return false
  }

  if (recruitmentLink.capture_country && demographicProfile.country === null) {
    return false
  }

  if (
    recruitmentLink.capture_demographic_attribute_ids.some((id) => {
      const attribute = demographics.find((a) => a.id === id)
      if (!attribute) return false

      const possibleOptionIds = attribute.options.map((o) => o.id)
      const actualOptionIds =
        demographicProfile.demographic_attribute_option_ids

      return intersection(possibleOptionIds, actualOptionIds).length === 0
    })
  ) {
    return false
  }

  return true
}

export interface SectionState {
  phase: ResponsePhase
  question: UsabilityTestSectionQuestion | null
  responseSection: Readonly<UnpersistedResponseSection> | null
  usabilityTestSection: UsabilityTestSection
}

export function calculateSectionState(
  usabilityTest: Readonly<RawParticipantUsabilityTest>,
  section: UsabilityTestSection,
  response: Readonly<ParticipantResponse>
): SectionState | null {
  const responseSection =
    response.sections.find(
      (rs) => rs.usability_test_section_id === section.id
    ) || null

  // Has this section been completed? If so then move on.
  if (responseSection !== null && responseSection.total_duration_ms !== null) {
    return null
  }

  // Only check for section tasks and questions if it's not excluded for test logic reasons.
  if (shouldAppearInTest(section, response, usabilityTest)) {
    const hasTask =
      section.type !== UsabilityTestSectionType.Questions &&
      section.type !== UsabilityTestSectionType.DesignQuestions

    if (
      hasTask &&
      (!isTaskComplete(responseSection) ||
        requiresTaskFlowSuccessAcknowledged(
          section,
          responseSection?.total_duration_ms,
          responseSection?.figma_file_version_answer,
          responseSection?._taskFlowSuccessAcknowledged
        ))
    ) {
      return {
        phase: ResponsePhase.TakingTest,
        question: null,
        responseSection,
        usabilityTestSection: section,
      }
    }

    // Task is complete, but the section is incomplete. Check if there are any
    // questions to complete.
    for (const question of section.questions) {
      if (
        isUnanswered(question, response.answers) &&
        shouldAppearInTest(question, response, usabilityTest)
      ) {
        return {
          phase: ResponsePhase.TakingTest,
          question,
          responseSection,
          usabilityTestSection: section,
        }
      }
    }
  }

  return null
}

export function calculateResponseState(
  usabilityTest: Readonly<RawParticipantUsabilityTest>,
  recruitmentLink: RecruitmentLink,
  response: Readonly<ParticipantResponse>,
  demographics: DemographicAttribute[]
): ResponseState {
  // If the test is recruited (not ordered) then check if all requested
  // demographics are filled.
  if (
    !isPanelOrdered(response) &&
    !response._hasCompletedDemographicQuestions &&
    recruitmentLink?.enable_demographics &&
    !areRequestedDemographicsPresent(
      response.response_demographic_profile ?? null,
      recruitmentLink,
      demographics
    )
  ) {
    return {
      phase: ResponsePhase.DemographicQuestions,
      question: null,
      responseSection: null,
      usabilityTestSection: null,
    }
  }

  // Search sections for something that's incomplete.
  for (const section of usabilityTest.sections) {
    const sectionResponseState = calculateSectionState(
      usabilityTest,
      section,
      response
    )
    if (sectionResponseState !== null) {
      return sectionResponseState
    }
  }

  return {
    phase: ResponsePhase.Complete,
    question: null,
    responseSection: null,
    usabilityTestSection: null,
  }
}

/**
 * Given a ParticipantResponse, work out whether their answers have triggered
 * a behaviour on the given section or question.
 */
export const shouldAppearInTest = (
  object: UsabilityTestSectionOrQuestion,
  response: RawParticipantResponse,
  usabilityTest: RawParticipantUsabilityTest
) => {
  const tls = object.test_logic_statement
  if (tls) {
    let targetValues: string[] = []

    if (tls.target_type === "UsabilityTestSection") {
      const questionAnswer = getAnswerForSection(
        tls.target_id,
        response.sections,
        usabilityTest.sections
      )
      if (questionAnswer) targetValues = [questionAnswer]
    } else if (tls.target_type === "UsabilityTestSectionQuestion") {
      targetValues = getAnswerForQuestion(
        tls.target_id,
        response.answers,
        usabilityTest.sections
      )
    }

    const atLeastOneTargetValueMatches = targetValues.some((tv) =>
      tls.values.includes(tv)
    )

    // Work out whether to show or hide based on the value we're comparing.
    switch (tls.behaviour) {
      case TestLogicBehaviour.SHOW:
        // If the answer matches and we want to show on match, show.
        return atLeastOneTargetValueMatches
      case TestLogicBehaviour.HIDE:
        // If the answer doesn't match and we want to hide on match, show.
        return !atLeastOneTargetValueMatches
      default:
        // Show everything by default
        return true
    }
  } else {
    // No test logic, show it
    return true
  }
}

/**
 * Currently only supports Preference Tests and Prototype Tasks.
 * We may add support for other section types in future.
 */
const getAnswerForSection = (
  targetId: number | null,
  responseSections: ReadonlyArray<Readonly<UnpersistedResponseSection>>,
  testSections: ReadonlyArray<UsabilityTestSection>
): string | null => {
  const responseSection = responseSections.find(
    (s) => s.usability_test_section_id === targetId
  )
  const testSection = testSections.find((s) => s.id === targetId)
  if (responseSection && testSection) {
    switch (testSection.type) {
      case UsabilityTestSectionType.PreferenceTest:
        return getScreenshotNameForResponseSection(responseSection, testSection)
      case UsabilityTestSectionType.PrototypeTask:
        return getPrototypeTaskCompletionForResponseSection(
          responseSection,
          testSection
        )
      default:
        throw new TypeError(`Unsupported section type ${testSection.type}`)
    }
  }

  return null
}

const getAnswerForQuestion = (
  targetId: number | null,
  answers: ReadonlyArray<Unpersisted<ResponseAnswer>>,
  testSections: ReadonlyArray<UsabilityTestSection>
): string[] => {
  const targetQuestion = testSections
    .flatMap((s) => s.questions)
    .find((q) => q.id === targetId)
  const targetAnswer = answers.find(
    (a) => a.usability_test_section_question_id === targetId
  )
  if (targetAnswer && targetQuestion) {
    // If it's a radio button and they used the free text field, normalise to "Other" for comparison.
    if (
      isRadioQuestion(targetQuestion) &&
      targetAnswer.answer &&
      !targetQuestion.multiple_choice_options.includes(targetAnswer.answer)
    ) {
      return ["Other"]
    }

    // If it's a checkbox, return an array of all answers with "Other" if they used the free text field.
    if (isCheckboxQuestion(targetQuestion)) {
      return targetAnswer.answers.map((answer) => {
        return targetQuestion.multiple_choice_options.includes(answer)
          ? answer
          : "Other"
      })
    }

    return targetAnswer.answer ? [targetAnswer.answer] : []
  }

  return []
}

/**
 * Return the name of the screenshot that the participant selected in the ResponseSection.
 */
const getScreenshotNameForResponseSection = (
  responseSection: UnpersistedResponseSection,
  testSection: UsabilityTestSection
): string | null => {
  const screenshots = testSection.section_screenshots

  const selectedScreenshot = screenshots
    ? screenshots.find(
        (ss) =>
          ss.id ===
          responseSection.selected_usability_test_section_screenshot_id
      )
    : null

  if (selectedScreenshot) {
    return selectedScreenshot.screenshot_name
  }

  return null
}

const isUnanswered = (
  question: UsabilityTestSectionQuestion,
  answers: ReadonlyArray<Unpersisted<ResponseAnswer>>
): boolean => {
  const answer = answers.find(
    (a) => a.usability_test_section_question_id === question.id
  )

  return answer === undefined || answer.duration_ms === null
}

/**
 * Return whether the participant reached the goal screen in the ResponseSection.
 */
const getPrototypeTaskCompletionForResponseSection = (
  responseSection: UnpersistedResponseSection,
  testSection: UsabilityTestSection
): PrototypeResponseState => {
  const goalNodeId = testSection.figma_file_flow?.goal_node_id

  if (
    goalNodeId &&
    responseSection.figma_file_version_answer?.data.find(
      (datum) =>
        datum.figma_message.type === "PRESENTED_NODE_CHANGED" &&
        datum.figma_message.data.presentedNodeId === goalNodeId
    )
  ) {
    return PrototypeResponseState.Completed
  }

  return PrototypeResponseState.NotCompleted
}

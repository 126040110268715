import { Box } from "@chakra-ui/react"
import { BoxProps } from "@chakra-ui/react"
import React from "react"
import { useTabsContext } from "./Context"

type PanelProps = BoxProps & {
  value: string
}

export const Panel = ({ id: _, value, children, ...props }: PanelProps) => {
  const { contextId, selected, isLazy } = useTabsContext()
  const panelId = `${contextId}:tabpanel:${value}`
  const tabId = `${contextId}:tab:${value}`
  const isHidden = selected !== value

  if (isLazy && isHidden) return null

  return (
    <Box
      {...props}
      display={isHidden ? "none" : undefined}
      id={panelId}
      role="tabpanel"
      aria-labelledby={tabId}
    >
      {children}
    </Box>
  )
}

import { Link, Text } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Alert } from "DesignSystem/components/Alert"
import {
  ExternalStudyTag,
  UsabilityTestTag,
} from "Shared/components/StudyTypeTags"
import { centsToDollarsAndCents, creditsToCents } from "Utilities/currency"
import { formatMinutes } from "Utilities/duration"
import { capitalizeFirstLetter } from "Utilities/string"
import React from "react"
import TestInterfaceApi from "~/api/TestInterfaceApi"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { PanelOrderAssignment } from "~/api/generated/usabilityhubSchemas"
import { AssignmentCard } from "../AssignmentCard/AssignmentCard"
import { AssignmentCardStudyDetailTags } from "../AssignmentCard/AssignmentCardStudyDetailTags"

type Props = {
  assignment: PanelOrderAssignment
}

export const UsabilityTestCard: React.FC<Props> = ({ assignment }) => {
  const { data: panelistInfo } = useCurrentPanelistInfo({})

  const isExternalStudy = assignment.is_external
  const isLiveWebsiteTest = assignment.is_live_website_test
  // NOTE: LWT is only supported on desktop browsers, but we use the "permitted
  // device type" for that check
  const isLiveWebsiteTestCompatibleBrowser =
    "documentPictureInPicture" in window
  const isLiveWebsiteTestAndIncompatibleBrowser =
    isLiveWebsiteTest && !isLiveWebsiteTestCompatibleBrowser

  const learnMoreUrl = isExternalStudy
    ? Constants.PANELIST_HELP_EXTERNAL_STUDY_GUIDELINES_URL
    : Constants.PANELIST_HELP_TEST_TAKING_GUIDELINES_URL

  const learnMoreLink = (
    <Link
      variant="noUnderline"
      whiteSpace="nowrap"
      href={learnMoreUrl}
      rel="noopener noreferer"
      target="_blank"
    >
      Learn more
    </Link>
  )

  return (
    <AssignmentCard
      studyTypeTag={
        isExternalStudy ? <ExternalStudyTag /> : <UsabilityTestTag />
      }
      incentiveText={centsToDollarsAndCents(
        creditsToCents(assignment.credit_value)
      )}
      tags={
        <AssignmentCardStudyDetailTags
          permittedDeviceType={assignment.permitted_device_type}
          recordingTypes={assignment.recording_types}
          hasScreener={assignment.has_screener}
        />
      }
      estimatedLengthText={capitalizeFirstLetter(
        formatMinutes(assignment.estimated_duration_minutes)
      )}
      participateAction={TestInterfaceApi.doOrderedTest.path({
        panelist_order_assignment_id: assignment.id,
        query: {
          source: "dashboard",
        },
      })}
      participateLabel="Participate"
      permittedDeviceType={assignment.permitted_device_type}
      currentDeviceType={panelistInfo?.current_device_type ?? "desktop"}
      disableParticipationButton={isLiveWebsiteTestAndIncompatibleBrowser}
    >
      <Text textStyle="ds.paragraph.primary">
        {isExternalStudy ? (
          <>
            For this test, you will be directed to an external website to
            complete a series of tasks and questions. {learnMoreLink}
          </>
        ) : (
          <>
            You will be asked to complete a series of tasks and answer
            questions. {learnMoreLink}
          </>
        )}
      </Text>
      {isLiveWebsiteTestAndIncompatibleBrowser && (
        <Alert
          description="This test is supported only on Chromium-based desktop browsers like Chrome and Edge. Switch to a compatible browser to participate."
          status="warning"
        />
      )}
    </AssignmentCard>
  )
}
